<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
            <el-form-item label="设备类型">
          <el-select v-model="searchForm.region" size="small" placeholder="设备类型">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告警类型">
          <el-select v-model="searchForm.region" size="small" placeholder="告警类型">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="通知设置列表">
        <template slot="btns">
          <el-button type="success" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
        </template>
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="date"
            label="设备类型"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="name"
            align="center"
            label="告警类型"
          >
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="通知角色">
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="操作员">
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="添加时间">
        </el-table-column>
       
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
      </custom-tables>
  
      <custom-dialog :visible="showDialog" title="新增控制" @close="close" @submit="submit">
        <div>弹出框</div>
      </custom-dialog>
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import CustomDialog from "../../../components/common/CustomDialog";
  export default {
    name: "index",
    components: {CustomDialog, CustomTables, SearchHead},
    data() {
      return {
        tableData: [
        
        ],
        searchForm:{},
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      }
    },
    mounted() {
    },
    methods:{
      search(){
        console.log('searchForm', this.searchForm)
      },
      reset(){
        this.searchForm = {}
      },
      addFrom(){
        this.showDialog = true
      },
      close(){
        this.showDialog = false
      },
      submit(){
        this.showDialog = false
      },
      detailItem(){
        //详情
    },
    deleteItem(){

    },
    handleSelectionChange(val){
        console.log(val);
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  
    }
  }
  </script>
  
  <style scoped lang="less">
  .detail{
    color: #008f4d;
}
.delete{
    margin-left: 10px;
}
.addBtn{
    background-color: #008f4d;
}
  </style>